import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Process = makeShortcode("Process");
const Link = makeShortcode("Link");
const Box = makeShortcode("Box");
const Chocolate = makeShortcode("Chocolate");
const Flex = makeShortcode("Flex");
const Icon = makeShortcode("Icon");
const Button = makeShortcode("Button");
const Column = makeShortcode("Column");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "développement",
      "style": {
        "position": "relative"
      }
    }}>{`Développement`}<a parentName="h1" {...{
        "href": "#d%C3%A9veloppement",
        "aria-label": "développement permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`Le but de Kernel n'est `}<strong parentName="p">{`pas de vous submerger`}</strong>{` avec des listes de liens. La vérité est qu'il existe un grand nombre d'outils de codage, chacun d'entre eux offre des compromis différents et pourra être le meilleur ou le pire en fonction de vos besoins et du contexte. C'est à cela que servent les mentors : nous vous recommandons de passer plus de temps à écouter leur avis qu'à parcourir des listes interminables d'outils expérimentaux à moitié maintenus.`}</p>
    <p>{`Plutôt que de recommander des IDE, des normes, des éditeurs, des meilleures pratiques ou quoi que ce soit d'autre, `}<strong parentName="p">{`cette page a pour but de vous aider à approfondir vos compétences en Solidity`}</strong>{`. Nous savons qu'il existe de nombreuses approches différentes des contrats intelligents, et nous savons que Solidity a ses problèmes, mais il est le plus largement utilisé et le plus accessible aux développeurs web actuels et il reste donc notre centre d'intérêt ici.`}</p>
    <h2 {...{
      "id": "le-chemin-direct",
      "style": {
        "position": "relative"
      }
    }}>{`Le chemin Direct`}<a parentName="h2" {...{
        "href": "#le-chemin-direct",
        "aria-label": "le chemin direct permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Il existe, bien sûr, de nombreux chemins vers le nirvana des contrats intelligents. Nous en présenterons plusieurs sur cette page, mais voici le chemin le plus court que nous connaissons pour une maîtrise permanente :`}</p>
    <Process mdxType="Process">
      <p><strong parentName="p">{`Débutant`}</strong>{` - `}<Link to="https://cryptozombies.io/" mdxType="Link">{`CryptoZombies`}</Link>{`. Vous ne connaissez rien aux smart contracts ou à solidity. Ce cours interactif vous permettra de vous familiariser avec les concepts de base.`}</p>
      <p><strong parentName="p">{`Débutant`}</strong>{` - `}<Link to="https://eth.build/" mdxType="Link">{`Eth.build`}</Link>{`. Un bac à sable éducatif pour web3 qui vous permet de coder avec des blocs, un peu comme dans Scratch. Bien sûr, vous pouvez faire des choses vraiment impressionnantes avec cet outil, mais il s'agit d'un merveilleux outil de programmation visuelle qui vous aidera à développer une intuition du fonctionnement des contrats intelligents et de la différence de l'environnement dans lequel ils vivent.`}</p>
      <p><strong parentName="p">{`Novice`}</strong>{` - `}<Link to="https://www.youtube.com/watch?v=v_hU0jPtLto&list=PL16WqdAj66SCOdL6XIFbke-XQg2GW_Avg" mdxType="Link">{`Will It Scale`}</Link>{`. Il s'agit toujours de notre série de tutoriels YouTube préférée. Elle vous permettra de découvrir l'EDI Remix et toute une série de concepts beaucoup plus profonds dans Solidity. Bien qu'il ne soit généralement pas très utile de regarder des vidéos plutôt que de coder, cette série vous permettra de mieux appréhender ce qui vous attend.`}</p>
      <p><strong parentName="p">{`Novice`}</strong>{` - `}<Link to="https://www.youtube.com/channel/UCJWh7F3AFyQ_x01VKzr9eyA/videos" mdxType="Link">{`Smart Contract Programmer`}</Link>{`. Une autre merveilleuse chaîne YouTube qui vous permet d'apprendre beaucoup de choses sans fioritures ni battage médiatique.`}</p>
      <p><strong parentName="p">{`Intermédiaire`}</strong>{` - `}<Link to="https://ethernaut.openzeppelin.com/" mdxType="Link">{`Ethernaut`}</Link>{`. Cela commence simplement, mais contient de nombreux défis et surprises pour ceux qui sont plus familiers avec Solidity et le contexte global et public dans lequel vivent les contrats intelligents. Vous pouvez trouver une présentation détaillée des douze premiers défis dans notre guilde `}<a parentName="p" {...{
          "href": "/build/outsmarting-contracts"
        }}>{`Outsmarting Contracts`}</a>{`.`}</p>
      <p><strong parentName="p">{`Intermédiaire`}</strong>{` - `}<Link to="https://speedrunethereum.com/" mdxType="Link">{`Speed Run Ethereum`}</Link>{`. Un ensemble de tutoriels approfondis en cours d'élaboration qui exploitent le cadre `}<Link to="https://github.com/scaffold-eth/scaffold-eth" mdxType="Link">{`scaffold-eth`}</Link>{`. Le framework est spécifiquement destiné à l'expérimentation rapide. Il vaut la peine de vérifier toutes les branches de ce repo pour des pertes de code différentes pertinentes pour tout, des NFT aux échanges et au-delà.`}</p>
      <p><strong parentName="p">{`Intermédiaire`}</strong>{` - `}<Link to="https://www.learnweb3.io/" mdxType="Link">{`LearnWeb3`}</Link>{` et `}<Link to="https://buildspace.so/" mdxType="Link">{`Build Space`}</Link>{`. Deux merveilleux programmes basés sur des cohortes qui se concentrent exclusivement sur les compétences techniques. Construire avec d'autres est toujours beaucoup plus amusant et instructif.`}</p>
      <p><strong parentName="p">{`Avancé`}</strong>{` - `}<Link to="https://moonshotcollective.space/" mdxType="Link">{`Moonshot Collective`}</Link>{`. Vous remarquerez que continuer à faire des tutoriels et des projets d'exemple ne vous fera pas passer le stade intermédiaire. Si vous voulez maîtriser une compétence, vous devrez éventuellement plonger dans des parties inexplorées de l'océan et viser les étoiles, sans trop vous soucier de ce que les gens penseront de vos métaphores mélangées ou de vos engagements étranges.`}</p>
    </Process>
    <h2 {...{
      "id": "le-chemin-documenté",
      "style": {
        "position": "relative"
      }
    }}>{`Le chemin Documenté`}<a parentName="h2" {...{
        "href": "#le-chemin-document%C3%A9",
        "aria-label": "le chemin documenté permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <Box sx={{
      backgroundColor: 'primaryMuted',
      borderRadius: '12px',
      padding: '1rem',
      display: 'flex',
      marginBottom: '1rem',
      flexDirection: 'column',
      flexWrap: 'wrap'
    }} mdxType="Box">
      <Chocolate sx={{
        marginBottom: '1.5rem'
      }} mdxType="Chocolate">
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Solidity ReadtheDocs`}</strong></p>
          <p>{`Tout ce que vous avez toujours voulu savoir sur le fonctionnement de Solidity.`}</p>
          <Button sx={{
            position: 'absolute',
            bottom: '-40px'
          }} to="https://docs.soliditylang.org/en/latest/introduction-to-smart-contracts.html" mdxType="Button">
  Lire
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`OpenZeppelin Docs`}</strong></p>
          <p>{`Avec les documents de Solidity eux-mêmes, c'est une source de code et de bonnes pratiques qui fait autorité et que notre parti décentralisé ne pourra probablement jamais obtenir.`}</p>
          <Button sx={{
            position: 'absolute',
            bottom: '-40px'
          }} to="https://docs.openzeppelin.com/openzeppelin/" mdxType="Button">
  Sécuriser
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Learn XinY Minutes`}</strong></p>
          <p>{`L'un des moyens les plus rapides de se familiariser avec Solidity. En fait, vous pouvez faire un tour panoramique de tous vos langages préférés ici...`}</p>
          <p><em parentName="p">{`H/T Eman Herawy`}</em></p>
          <Button sx={{
            position: 'absolute',
            bottom: '-40px'
          }} to="https://learnxinyminutes.com/docs/solidity/" mdxType="Button">
  Apprendre
          </Button>
        </Flex>
      </Chocolate>
    </Box>
    <h2 {...{
      "id": "le-chemin-listé",
      "style": {
        "position": "relative"
      }
    }}>{`Le chemin Listé`}<a parentName="h2" {...{
        "href": "#le-chemin-list%C3%A9",
        "aria-label": "le chemin listé permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Bien que nous évitions spécifiquement ce genre de listes dans Kernel, si vous êtes le genre de personne qui recherche une grande quantité de détails et tous les outils, normes et meilleures pratiques possibles développés à travers le monde, alors cette section est pour vous.`}</p>
    <p>{`L'accent étant mis sur l'interdisciplinarité dans notre section Ressources, il convient de noter que vous pouvez trouver des listes plus intéressantes - et le type d'approche prudente, étape par étape, conservatrice qu'elles impliquent - dans notre `}<a parentName="p" {...{
        "href": "/resources/security/#praxis"
      }}>{`page Sécurité`}</a>{`.`}</p>
    <Column mdxType="Column">
      <Flex sx={{
        position: 'relative',
        flexDirection: 'column'
      }} mdxType="Flex">
        <p><strong parentName="p">{`Ressources pour Développeur `}</strong></p>
        <p>{`La liste des ressources de la Fondation Ethereum. Pour ceux qui sont nouveaux, c'est-à-dire tout le monde.`}</p>
        <Button sx={{
          position: 'absolute',
          bottom: '-40px'
        }} to="https://ethereum.org/en/developers/" mdxType="Button">
  Lire
        </Button>
      </Flex>
      <Flex sx={{
        position: 'relative',
        flexDirection: 'column'
      }} mdxType="Flex">
        <p><strong parentName="p">{`Liste d'outils`}</strong></p>
        <p>{`Une liste complète et bien entretenue de ressources et d'outils pour les développeurs d'Ethereum.`}</p>
        <Button sx={{
          position: 'absolute',
          bottom: '-40px'
        }} to="https://github.com/ConsenSys/ethereum-developer-tools-list" mdxType="Button">
  Écrire
        </Button>
      </Flex>
    </Column>
    <h2 {...{
      "id": "le-chemin-inconnu",
      "style": {
        "position": "relative"
      }
    }}>{`Le chemin Inconnu`}<a parentName="h2" {...{
        "href": "#le-chemin-inconnu",
        "aria-label": "le chemin inconnu permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Maintenant que vous avez parcouru de nombreux chemins à travers `}<a parentName="p" {...{
        "href": "https://samczsun.com/escaping-the-dark-forest/"
      }}>{`la forêt obscure`}</a>{`, vous pouvez pousser un soupir de soulagement (sens littéral de "nirvana"). Mais seulement pour un instant ! Car les vrais maîtres agissent sans laisser de trace et - pour atteindre des états d'être aussi élevés - nous devons explorer certaines des limites de la recherche et de la cryptographie.`}</p>
    <p>{`Une fois de plus, étant donné l'orientation interdisciplinaire de ce site, les documents pertinents qui ne sont pas des outils peuvent être trouvés dans la `}<a parentName="p" {...{
        "href": "/ressources/recherche/#z%C3%A9ro-connaissance"
      }}>{`section Recherche`}</a>{`.`}</p>
    <Box sx={{
      backgroundColor: 'primaryMuted',
      borderRadius: '12px',
      padding: '1rem',
      display: 'flex',
      marginBottom: '1rem',
      flexDirection: 'column',
      flexWrap: 'wrap'
    }} mdxType="Box">
      <Chocolate sx={{
        marginBottom: '1.5rem'
      }} mdxType="Chocolate">
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`zkREPL`}</strong></p>
          <p>{`"L'outillage circom que j'utilise le plus. Passez la souris sur les variables pour voir les valeurs."`}</p>
          <p><em parentName="p">{`H/T Justin Glibert`}</em></p>
          <Button sx={{
            position: 'absolute',
            bottom: '-40px'
          }} to="https://zkrepl.dev/" mdxType="Button">
  Itérer
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Circom & SnarkJS`}</strong></p>
          <p>{`Un compilateur de circuits zkSnark et une mise en œuvre de zkSNARK en JavaScript et WASM, construits et `}<a parentName="p" {...{
              "href": "https://www.youtube.com/watch?v=-9TJa1hVsKA"
            }}>{`expliqués ici par Jordi Baylina`}</a>{`.`}</p>
          <Button sx={{
            position: 'absolute',
            bottom: '-40px'
          }} to="https://github.com/iden3" mdxType="Button">
  Construire
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`ZoKrates`}</strong></p>
          <p>{`Une boîte à outils pour les zkSNARKs sur Ethereum.`}</p>
          <p><em parentName="p">{`H/T Luka`}</em></p>
          <Button sx={{
            position: 'absolute',
            bottom: '-40px'
          }} to="https://github.com/zokrates/zokrates" mdxType="Button">
  Prouver
          </Button>
        </Flex>
      </Chocolate>
    </Box>
    <h2 {...{
      "id": "enregistrements",
      "style": {
        "position": "relative"
      }
    }}>{`Enregistrements`}<a parentName="h2" {...{
        "href": "#enregistrements",
        "aria-label": "enregistrements permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Bien sûr, aucune liste de ressources sur les contrats intelligents ne serait complète sans un enregistrement d'Austin Griffith cassant quelque chose dans une démonstration en direct tout en portant un nœud papillon :`}</p>
    <Video src="https://www.youtube-nocookie.com/embed/UFLcw1ftrv0?start=102" mdxType="Video" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      